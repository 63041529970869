import { t } from "@lingui/macro";
import { getPointLockStateLabel } from "../point/point.utils";

export const pageSizes = [10, 20, 50];
export const defaultPageSize = 10;
export const lockStateFilterOptions = {
  0: getPointLockStateLabel(0),
  1: getPointLockStateLabel(1),
  2: getPointLockStateLabel(2),
};
export const invalidCharacters = /^[^<>&"'\\/:*?|]*$/;
export const commonDebounceDelay = 400;
// The maximum number of alarms shown in the bell icon
export const alarmBellLimit = 10;
// TEMPORARY - set the maximum amount of alarms fetched in one query
export const alarmFetchLimit = 500;

export type QueryOptions = {
  enabled: boolean;
};

export function getDefaultTableTexts() {
  return {
    activeFiltersText: t`Active filters:`,
    clearAllFiltersButtonText: t`CLEAR`,
    emptyResultsText: t`No results found`,
    filtersSelectedText: t`selected`,
    searchPlaceholderText: t`Search`,
  };
}
