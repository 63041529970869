import { useState } from "react";
import { Select } from "@fidelix/fx-miranda";
import { styled } from "styled-components";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { useCompaniesQuery } from "~company/company.queries";
import { useRequiredParams } from "~utils/url";

export function CompanySelect() {
  const { companyId } = useRequiredParams({ companyId: "string" });
  const { data: companies = [] } = useCompaniesQuery();
  const [selectedCompany, setSelectedCompany] = useState(companyId);
  const navigate = useNavigate();
  const location = useLocation();

  function handleSelect(value: string | number | null) {
    if (!value) {
      return;
    }
    const match = matchPath("/company/:companyId/*", location.pathname);
    const route = match?.params["*"] || "dashboard";
    setSelectedCompany(String(value));
    navigate(`/company/${value}/${route}`);
  }
  const selectWidth =
    companies.length > 0
      ? companies.find((c) => c.id === +companyId)?.name.length
      : undefined;

  return (
    <NavSelect
      isFilterable={true}
      data-test-id="company-select"
      aria-label="Companies"
      placeholder={
        companies.find((c) => c.id === Number(selectedCompany))?.name || ""
      }
      selected={selectedCompany}
      onSelect={handleSelect}
      maxHeight="350px"
      $width={selectWidth ? selectWidth * 10 : 250}
    >
      {companies.map((company) => (
        <Select.Option key={company.id} value={String(company.id)}>
          {company.name}
        </Select.Option>
      ))}
    </NavSelect>
  );
}

const NavSelect = styled(Select)<{ $width: number }>`
  min-width: 250px;
  width: ${(p) => p.$width}px;
  button {
    background-color: ${(p) => p.theme.colors.white};
  }
`;
