import { queryOptions, useQuery } from "@tanstack/react-query";
import { assetsApiRequest } from "~api/api-request";
import { ApiResponse } from "~api/api-response.model";
import { useRequiredParams } from "~utils/url";
import { Graphics, GraphicsAttachment } from "./graphics.model";

type GraphicsParams = {
  companyId: number;
  graphicsId: number;
};

export const graphicsQueries = {
  one: (params: GraphicsParams) =>
    queryOptions({
      queryKey: ["devices-graphics", params],
      queryFn: ({ signal }) => fetchGraphics(params, signal),
    }),
  attachments: (params: GraphicsParams) =>
    queryOptions({
      queryKey: ["graphics-attachments", params],
      queryFn: ({ signal }) => fetchGraphicsAttachments(params, signal),
    }),
};

export function useGraphicsQuery({
  graphicsId,
  enabled = true,
}: {
  graphicsId: number;
  enabled?: boolean;
}) {
  const { companyId } = useRequiredParams({ companyId: "number" });
  return useQuery({
    enabled,
    ...graphicsQueries.one({ companyId, graphicsId }),
  });
}

export function useGraphicsAttachmentsQuery(graphicsId: number) {
  const { companyId } = useRequiredParams({ companyId: "number" });
  return useQuery(graphicsQueries.attachments({ companyId, graphicsId }));
}

async function fetchGraphics(params: GraphicsParams, signal?: AbortSignal) {
  return assetsApiRequest
    .get<
      ApiResponse<Graphics>
    >(`analytics/companies/${params.companyId}/graphics/${params.graphicsId}`, { signal })
    .then((resp) => new Graphics(resp.data.data));
}

async function fetchGraphicsAttachments(
  params: GraphicsParams,
  signal?: AbortSignal,
) {
  return assetsApiRequest
    .get<
      ApiResponse<GraphicsAttachment[]>
    >(`analytics/companies/${params.companyId}/graphics/${params.graphicsId}/attachments`, { signal })
    .then((resp) =>
      resp.data.data.map((attachment) => new GraphicsAttachment(attachment)),
    );
}
