import { t } from "@lingui/macro";
import { isNil } from "~utils/value";
import { DateTime } from "luxon";
import { LockState, Point, PointType, PointValue } from "./point.model";

export function pointTypeFromNumber(type: number): PointType {
  return type in PointType ? (type as PointType) : PointType.None;
}

export function getPointTypeLabel(type: PointType) {
  const pointTypeText: Record<PointType, string> = {
    0: t`Unknown`,
    1: t`Indication`,
    2: t`DO point`,
    3: t`Measurement`,
    4: t`AO point`,
    5: t`Conversion table`,
    6: t`Time schedule`,
    7: t`Alarm`,
    13: t`Control point`,
    17: t`Calendar`,
  };

  return pointTypeText[type] || t`Unknown`;
}

export function getIsPointErrorState(
  errorState: number | null | undefined,
): boolean {
  return !isNil(errorState) && errorState !== 0;
}

export function getPointLockStateLabel(lockState?: LockState | null) {
  const lockStateText: Record<LockState, string> = {
    0: t`Auto`,
    1: t`Program`,
    2: t`Manual`,
  };

  return lockStateText[lockState as LockState] || "-";
}

export function getPointValueLabel({ type, value, unit }: Point) {
  // Some types of points don't have a value that can be displayed
  if (
    !value ||
    type === PointType.Calendar ||
    type === PointType.ConversionTable
  ) {
    return "-";
  }

  // If point has state text based label, use it
  if (value.label) {
    return value.label;
  }

  if (isNil(value.value)) {
    return "-";
  }

  // If point has a unit, append it to the value
  if (unit) {
    return `${value.value} ${unit}`;
  }

  return value.value;
}

export type PointLastUpdateLocale = {
  localeDate: string;
  localeTime: string;
};

export function getPointLastUpdateLocale(
  value?: PointValue,
  dateFormat?: string,
  timeFormat?: string,
): PointLastUpdateLocale | undefined {
  if (!value) {
    return undefined;
  }
  try {
    return {
      localeDate: DateTime.fromISO(value.lastUpdate).toFormat(
        dateFormat ?? "D",
      ),
      localeTime: DateTime.fromISO(value.lastUpdate).toFormat(
        timeFormat ?? "HH:mm",
      ),
    };
  } catch {
    return undefined;
  }
}
