import { Color } from "@fidelix/fx-miranda";
import { t } from "@lingui/macro";
import { DeviceStatus } from "./device.model";

export function getDeviceStatusColor(status?: DeviceStatus): Color {
  switch (status) {
    case DeviceStatus.Connected:
      return "success";
    case DeviceStatus.Disabled:
      return "warning";
    case DeviceStatus.Timeout:
    case DeviceStatus.Idle:
      return "mutedDarkNeutral";
    default:
      return "error";
  }
}

export function getDeviceStatusText(status?: DeviceStatus): string {
  switch (status) {
    case DeviceStatus.Connected:
      return t`Connected`;
    case DeviceStatus.Idle:
      return t`Connecting`;
    case DeviceStatus.Disabled:
      return t`Disabled`;
    default:
      return t`Disconnected`;
  }
}

/**
 * Parse query parameter 'status' from the URL and convert it into a number array
 * This is done because selecting Disconnected selects multiple statuses at the same
 * time and this selection needs to be flattened into a single flat array
 */
export function parseStatusFromURL(status: string[]): number[] {
  return status.reduce<number[]>((a, c) => {
    const jsoned = JSON.parse(c);
    if (jsoned instanceof Array) {
      return [...a, ...jsoned.map(Number)];
    }
    return [...a, Number(c)];
  }, []);
}
